const colors = {
  primary: { base: '#F52332' },
  secondary: { base: '#353439' },
  accent: { base: '#7694B8' },
  error: { base: '#FF2D2E' },
  info: { base: '#51618B' },
  success: { base: '#00B040' },
  warning: { base: '#FFF8E0' },
  't-dark': { base: '#353439' },
  't-grey': { base: '#6F7882' },
  't-back-light': { base: '#F7F9FC' },
  't-menu-bg': { base: '#E5EBF4' },
  't-additional': { base: '#7694B8' },
  't-positive': { base: '#00B040' },
  't-attention': { base: '#FF6B00' },
  't-disabled': { base: '#E1E3E6' },
  't-pink': { base: '#F7CCD1' },
};

export default {
  isOlveryPlatform: true,
  img: {
    publicBg: require('./assets/public.jpg'),
    authLogo: require('./assets/logo.svg'),
    navLogo: require('./assets/logo.svg'),
    accessPoster: require('./assets/access_poster.png'),
  },
  themes: {
    light: colors,
    dark: colors,
  },
};
